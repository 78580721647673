import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { H3Input } from '@h3/thinking-ui';
let CommonInput = class CommonInput extends Vue {
    constructor() {
        super(...arguments);
        // 主样式名
        this.prefixCls = 'common-input';
    }
    // 双向绑定值
    get innerValue() {
        return this.value;
    }
    set innerValue(val) {
        let v = val;
        this.$emit('input', v);
        this.$nextTick(() => {
            if (this.format) {
                v = this.format(val);
            }
            this.$emit('input', v);
        });
    }
    /**
     * 获取焦事件
     */
    focus(e) {
        this.$emit('focus', e);
    }
    /**
     * 失去焦事件
     */
    blur(e) {
        this.$emit('blur', e);
    }
    /**
     * input事件
     */
    input(e) {
        this.$emit('input', e);
    }
    async mounted() { }
};
__decorate([
    Prop()
], CommonInput.prototype, "value", void 0);
__decorate([
    Prop({
        default: '请输入',
    })
], CommonInput.prototype, "placeholder", void 0);
__decorate([
    Prop({
        default: 'text',
    })
], CommonInput.prototype, "type", void 0);
__decorate([
    Prop({
        default: '',
    })
], CommonInput.prototype, "tip", void 0);
__decorate([
    Prop({
        default: '',
    })
], CommonInput.prototype, "errorTip", void 0);
__decorate([
    Prop({
        default: false,
    })
], CommonInput.prototype, "plaintext", void 0);
__decorate([
    Prop({
        default: true,
    })
], CommonInput.prototype, "required", void 0);
__decorate([
    Prop()
], CommonInput.prototype, "format", void 0);
__decorate([
    Prop()
], CommonInput.prototype, "showArea", void 0);
CommonInput = __decorate([
    Component({
        name: 'CommonInput',
        components: {
            H3Input,
        },
    })
], CommonInput);
export default CommonInput;
