import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { H3Button, H3Input } from '@h3/thinking-ui';
import CommonInput from './common-input.vue';
import { LoginService, OrgManageService } from '@/sevices';
import { RsaEncrypt } from '@/common/rsg';
import { namespace } from 'vuex-class';
const Global = namespace('Global');
let ChangePassword = class ChangePassword extends Vue {
    constructor() {
        super(...arguments);
        // 账号
        this.username = '';
        this.password = '';
        this.submitting = false;
        this.usernameShow = false;
        this.passwordShow = false;
        this.height = 0;
        this.loginError = '';
        // 加密公钥
        this.rsaKey = {};
    }
    // 是否可以登录
    get submitPassword() {
        return !(this.username && this.password);
    }
    /**
     * 如果是不是admin，则需要获取用户信息之后再跳转到运行时，运行时依赖这些用户信息
     */
    isNotAdminAndRuntimeUser() {
        const redirectUrl = this.$route.query.redirect_url || '';
        if (this.username !== 'admin' && redirectUrl.includes('app_runtime')) {
            return true;
        }
        return false;
    }
    /**
     * 获取组织CorpId
     */
    async getOrgCorpId() {
        const { data = [], success } = await OrgManageService.getOrgList();
        if (!success)
            throw new Error('获取组织异常');
        if (!Array.isArray(data.userOrgList))
            return;
        if (data.userOrgList) {
            const curOrg = data?.userOrgList[0]?.corpId;
            return curOrg;
        }
    }
    /**
     * 设置组织
     */
    async setOrg(id) {
        const { success, data = {}, } = await OrgManageService.setOrg(id);
        if (success) {
            // 流程组需要用到
            localStorage.setItem('corpInfo', JSON.stringify(data));
        }
        else {
            throw new Error('设置组织异常');
        }
    }
    /**
     * 重定向时需要获取用户信息并保存到缓存
     */
    async getLoginInfo() {
        const res = await OrgManageService.getLoginInfo();
        if (res.success) {
            const data = res.data || {};
            const user = {
                userInfo: { id: data.id, name: data.name, username: data.username, type: 3 },
                departmentInfo: { id: data?.mainDepartmentId, name: data?.mainDepartmentName, type: 1 },
            };
            // 运行态的选人控件默认值需要用到
            localStorage.setItem('user', JSON.stringify(user));
        }
        else {
            throw new Error('获取用户信息异常');
        }
    }
    /**
     * 登录
     */
    async submit() {
        this.submitting = true;
        // 获取publicKey
        LoginService.getKey().then((resKey) => {
            if (!resKey.success)
                return;
            var dataKey = resKey.data;
            const flag = typeof dataKey === 'object' && dataKey.index && dataKey.key;
            if (!flag)
                return;
            var { index, key } = dataKey;
            const password = RsaEncrypt(this.password, key);
            // rsa加密结束
            const params = {
                username: this.username,
                password,
                client_id: 'api',
                index,
            };
            // 登录
            return LoginService.login(params);
        }).then(async (res) => {
            if (!res.success || !res.data)
                return;
            this.setInfoToLocal(res.data);
            if (res.data.specification) {
                localStorage.setItem('sessToken', res.data.access_token);
                this.submitting = false;
                this.$modal.confirm({
                    title: '',
                    content: '密码不符合规则，请前往修改',
                    onConfirm: async () => {
                        this.$router.push({
                            name: 'm-work-platform-change-password',
                        }).catch(() => { });
                    },
                    onCancel() {
                        // console.log('点击取消按钮');
                    },
                });
                return new Promise(() => { });
            }
            this.getToken(res.data);
            // 获取权限
            return this.getAllPerms();
        }).then(() => {
            if (this.isNotAdminAndRuntimeUser()) {
                return this.getOrgCorpId();
            }
        }).then((corpId) => {
            if (this.isNotAdminAndRuntimeUser()) {
                return this.setOrg(corpId);
            }
        }).then(() => {
            if (this.isNotAdminAndRuntimeUser()) {
                return this.getLoginInfo();
            }
        }).then(() => {
            if (process.env.NODE_ENV === 'production')
                return;
            if (this.isNotAdminAndRuntimeUser()) {
                return window.sendData();
            }
        }).then(() => {
            const redirectUrl = this.$route.query.redirect_url;
            if (redirectUrl) {
                window.location.href = redirectUrl.replace('hashsymbol', '#');
            }
            else {
                this.$router.replace({
                    name: 'm-work-platform-home',
                }).catch(() => { });
            }
        }).catch((e) => {
            const getErrorStatus = (error) => error.httpStatus || (error.response && error.response.status);
            const status = getErrorStatus(e);
            if (status === 403) {
                this.loginError = '账号或密码有误';
            }
        }).finally(() => {
            this.submitting = false;
            localStorage.removeItem('sessToken');
        });
    }
    /**
     * 获取token
     */
    async getToken(data) {
        const token = data.access_token;
        localStorage.setItem('token', token);
    }
    setInfoToLocal(data) {
        const userInfo = {
            name: data.userinfo ? data.userinfo.name : '-',
            username: data.userinfo ? data.userinfo.username : '-',
            mobile: data.mobile || '-',
            userId: data.user_id,
            baseUserId: data.userinfo.id,
            imgUrl: data.userinfo.imgUrl,
        };
        const publicUserInfo = userInfo;
        localStorage.setItem('publicUserInfo', JSON.stringify(publicUserInfo));
    }
    keyDownEnter(event) {
        const e = event || window.event;
        if (e && e.keyCode === 13) {
            if (!this.submitPassword && !this.submitting) {
                this.submit();
            }
        }
    }
    created() {
        window.document.title = '登录';
        document.addEventListener('keydown', this.keyDownEnter);
        this.height = document.body.clientHeight - 470;
    }
    destroyed() {
        document.removeEventListener('keydown', this.keyDownEnter);
    }
};
__decorate([
    Global.Action('getAllPerms')
], ChangePassword.prototype, "getAllPerms", void 0);
ChangePassword = __decorate([
    Component({
        name: 'ChangePassword',
        components: {
            H3Button,
            H3Input,
            CommonInput,
        },
    })
], ChangePassword);
export default ChangePassword;
